const inputGroups = document.querySelectorAll('.input--group')
const hits = [
	'feld-0',
	'feld-11',
	'feld-16',
	'feld-17',
	'feld-18',
	'feld-24',
	'feld-30',
	'feld-43',
	'feld-56',
	'feld-62',
	'feld-67',
	'feld-72',
	'feld-86',
	'feld-87',
	'feld-88',
	'feld-97',
	'feld-103',
	'feld-105',
	'feld-122',
	'feld-129',
	'feld-130',
	'feld-131',
	'feld-134',
	'feld-137',
	'feld-138',
	'feld-139',
	'feld-141',
	'feld-144',
	'feld-145',
	'feld-146',
	'feld-149',
	'feld-152',
	'feld-153',
	'feld-164',
	'feld-168',
	'feld-172',
	'feld-183',
]

inputGroups.forEach(g => {
	g.addEventListener('change', () => { 
		const inputItem = g.querySelector('input')
		const bild = g.querySelector('img')
		const hitSound = document.getElementById('hit--audio')
		const missSound = document.getElementById('miss--audio')
		inputItem.disabled = true
		
		
		
		if (inputItem.id === hitOrMiss(inputItem.id)[0]) {
			hitSound.pause()
			hitSound.currentTime = 0
			missSound.pause()
			missSound.currentTime = 0
			g.classList.add('hit')
			bild.src = "assets/img/treffer2.gif"
			hitSound.play()
			setTimeout(() => { bild.src = ""; bild.style.display = "none"}, 1000)
		} else {
			hitSound.pause()
			hitSound.currentTime = 0
			missSound.pause()
			missSound.currentTime = 0
			g.classList.add('miss')
			bild.src = "assets/img/miss.gif"
			missSound.play()
			setTimeout(() => { bild.src = ""; bild.style.display = "none"}, 1000)
		}

		if (isGameOver()) { 
			showModal()
		}

	})
})
const hitOrMiss = (id) => { 

	const isHit = hits.filter(hit => {
		if (id === hit) { 
			return hit
		} 
	})

	return isHit
}

const isGameOver = () => { 
	const inputs = document.querySelectorAll('.input--group input')
	let gameOver = true
	inputs.forEach(i => { 
		hits.forEach(h => { 
			if (i.id === h && i.disabled === false) {
				gameOver = false
			}
		})
	})
	return gameOver
}

const showModal = () => document.getElementById('endscreen').classList.add('active')